import { Models } from '$models';
import { Observable, filter, iif, map, of, switchMap } from 'rxjs';

interface MarkerType {
  props?: Models.PropertySearchResponse[] | null;
  savedIds: number[] | undefined;
}

/**
 * Convert property listings into google maps markers
 * @param props
 * @returns
 */
export const markerGenerate = ({ props, savedIds }: MarkerType) => {
  if (!props?.length) {
    return null;
  }
  return props.map(p => {
    const favoriteClass = savedIds?.includes(p.ITEM_ID!) ? 'marker-with-heart' : 'marker';
    const formattedDate = getFormattedDate(p.SOLD_DATE || p.DATE_END);
    const dimensions: [number, number] = formattedDate === 'TBD' ? [50, 32] : [105, 32];

    return {
      label: {
        text: formattedDate,
        className: favoriteClass,
      },
      clickable: true,
      position: { lat: p.LAT, lng: p.LONG },
      icon: {
        url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJAAAACQAQAAAADPPdLzAAAAZklEQVR4Xu3BAQEAAAABIP6PzgpVavAAAAAElFTkSuQmCC', // Transparent image
        size: new google.maps.Size(...dimensions),
        anchor: new google.maps.Point(dimensions[0] / 2, dimensions[1] / 2),
      },
      data: p,
    } as google.maps.MarkerOptions;
  });
};

/**
 * Generate a map center from query params
 * @param center
 * @returns
 */
export const mapCenterGenerate = (center: string | null) => {
  if (!center) {
    return null;
  }
  const coords = center.split(',');
  const t: google.maps.LatLngLiteral = {
    lat: Number(coords[0]),
    lng: Number(coords[1]),
  };
  return t;
};

/**
 * Misc util functions
 */

/**
 * Format date as mm/dd/yyyy
 * @param date
 * @returns
 */
const getFormattedDate = (dateSrc?: Date | string) => {
  if (!dateSrc) {
    return 'TBD';
  }

  const date = dateIsValid(new Date(dateSrc)) ? new Date(dateSrc) : new Date();
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString();
  const day = date.getDate().toString();
  return month + '/' + day + '/' + year;
};

const dateIsValid = (date: Date) => {
  return date instanceof Date && !isNaN(date as any);
};

export function observeWheelBasedInteractionWithTheMap(googleMap: google.maps.Map): Observable<void> {
  return reportEventWhenMouseOverTheMap(googleMap, 'wheel');
}

export function observeDoubleClickInteractionWithTheMap(googleMap: google.maps.Map): Observable<void> {
  return new Observable(observer => {
    const dblClickListener = googleMap.addListener('dblclick', () => {
      observer.next();
    });

    observer.add(() => {
      google.maps.event.removeListener(dblClickListener);
    });
  });
}

export function observeDraggingInteractionWithTheMap(googleMap: google.maps.Map): Observable<void> {
  return new Observable(observer => {
    const dragListener = googleMap.addListener('drag', () => {
      observer.next();
    });

    observer.add(() => {
      google.maps.event.removeListener(dragListener);
    });
  });
}

export function observeKeyboardInteracationWithTheMap(googleMap: google.maps.Map): Observable<void> {
  const mapContainer = googleMap.getDiv();
  const mapElement = mapContainer.querySelector('div[aria-label="Map"]');

  return iif(() => !!mapElement, of(mapElement), waitForMapBuild(googleMap)).pipe(
    switchMap(element => {
      if (!(element instanceof HTMLElement)) {
        throw new Error('wrong element, there should be an element which is able to recieve focus and therefore to intercepts keyboard events');
      }
      return getArrowKeyboardButtonsState(element);
    }),
    filter(state => state > 0),
    map(() => void 0),
  );
}

export function observePinchInteractionWithTheMap(googleMap: google.maps.Map): Observable<void> {
  return new Observable(observer => {
    const pinchListener = googleMap.addListener('idle', () => {
      observer.next();
    });

    observer.add(() => {
      google.maps.event.removeListener(pinchListener);
    });
  });
}

function waitForMapBuild(googleMap: google.maps.Map): Observable<HTMLElement> {
  const mapContainer = googleMap.getDiv();

  return new Observable(rxjsObserver => {
    const callback: MutationCallback = (mutationList: MutationRecord[], mutationObserver: MutationObserver) => {
      for (const element of mutationList) {
        for (let i = 0; i < element.addedNodes.length; i++) {
          const node: Node = element.addedNodes[i];
          if (!(node instanceof HTMLElement)) {
            return;
          }
          if (node.hasAttribute('aria-label') && node.getAttribute('aria-label') === 'Map') {
            mutationObserver.disconnect();
            rxjsObserver.next(node);
            return;
          }
        }
      }
    };

    const mutationObserver = new MutationObserver(callback);
    mutationObserver.observe(mapContainer, { childList: true, subtree: true });

    rxjsObserver.add(() => {
      mutationObserver.disconnect();
    });
  });
}

function getArrowKeyboardButtonsState(element: HTMLElement): Observable<number> {
  let isArrowButtonsPressed = 0b0000;

  return new Observable(observer => {
    element.addEventListener('keydown', keydownListener);
    element.addEventListener('keyup', keyupListener);

    observer.add(() => {
      element.removeEventListener('keydown', keydownListener);
      element.removeEventListener('keyup', keyupListener);
    });

    function keydownListener(event: KeyboardEvent) {
      if (!event.code.startsWith('Arrow')) {
        return;
      }
      // left
      if (event.code === 'ArrowLeft') {
        isArrowButtonsPressed = isArrowButtonsPressed | 0b1000;
      }
      // up
      if (event.code === 'ArrowUp') {
        isArrowButtonsPressed = isArrowButtonsPressed | 0b0100;
      }
      // right
      if (event.code === 'ArrowRight') {
        isArrowButtonsPressed = isArrowButtonsPressed | 0b0010;
      }
      // down
      if (event.code === 'ArrowDown') {
        isArrowButtonsPressed = isArrowButtonsPressed | 0b0001;
      }
      observer.next(isArrowButtonsPressed);
    }

    function keyupListener(event: KeyboardEvent) {
      if (!event.code.startsWith('Arrow')) {
        return;
      }

      if (event.code === 'ArrowLeft') {
        isArrowButtonsPressed = isArrowButtonsPressed & 0b0111;
      }
      // up
      if (event.code === 'ArrowUp') {
        isArrowButtonsPressed = isArrowButtonsPressed & 0b1011;
      }
      // right
      if (event.code === 'ArrowRight') {
        isArrowButtonsPressed = isArrowButtonsPressed & 0b1101;
      }
      // down
      if (event.code === 'ArrowDown') {
        isArrowButtonsPressed = isArrowButtonsPressed & 0b1110;
      }
      observer.next(isArrowButtonsPressed);
    }
  });
}

function reportEventWhenMouseOverTheMap(googleMap: google.maps.Map, event: keyof DocumentEventMap): Observable<void> {
  let isMouseOverTheMap = false;
  return new Observable(observer => {
    const mouseoverListener = googleMap.addListener('mouseover', () => {
      isMouseOverTheMap = true;
    });

    const mouseoutListener = googleMap.addListener('mouseout', () => {
      isMouseOverTheMap = false;
    });

    const eventListener = () => {
      if (isMouseOverTheMap) {
        observer.next();
      }
    };

    document.addEventListener(event, eventListener);

    observer.add(() => {
      google.maps.event.removeListener(mouseoverListener);
      google.maps.event.removeListener(mouseoutListener);
      document.removeEventListener(event, eventListener);
    });
  });
}
